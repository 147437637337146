* {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 16px;
  height: 100%;
  background-color: silver;
  margin: 0 8px;
}

.subtitle {
  font-size: 1.17em;
}

.share-calculator {
  margin: 0;
  background-color: #fff;
}

.share-calculator #main_container {
  padding: 40px;
  max-width: 904px;
  font-size: 0.9em;
}

a {
  color: #0176dc !important;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.table {
  padding: 10px;
}
.table,
.table th,
.table td {
  background-color: #f5f5f2;
}
.table td {
  text-align: left;
  padding-left: 20px;
}
.table tr:nth-child(2) td {
  padding: 10px;
  text-align: center;
  text-decoration: underline;
}

#main_container {
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 40px 34px;
  background-color: white;
  min-height: 100%;
}
#main_container .logo {
  max-width: 180px;
  margin: 0 auto;
  display: block;
}

#main_container.privacy p {
  line-height: 25px;
}
#main_container.privacy h3:nth-child(2) {
  text-align: center;
}

/* first two lines of page */
.bens-name {
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
}

.small_PDF_icon {
  width: 17px;
  height: 17px;
  margin-left: 10px;
}

.box_with_shadow {
  background-color: rgb(240, 255, 240);
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 0 5px 5px #888;
  -moz-box-shadow: 0 0 5px 5px #888;
  -webkit-box-shadow: 0 0 5px 5px #888;
}
thead {
  font-weight: bold;
}

td,
th,
#resultDiv p:last-child {
  text-align: center;
}

.number_entry_box {
  width: 4em;
}

.button {
  height: 30px;
  font-size: 0.9em;
  white-space: normal;
}
#resultDiv .button {
  margin: 15px;
}

.grades_and_weights {
  width: 70%;
  margin: auto;
  border: 0;
  border-spacing: 12px;
}

.entered_number {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
}

/* section one */
#section-one {
  clear: both;
}

#section-one div:first-child p a {
  word-wrap: break-word;
}
.question {
  padding-top: 40px;
}
.section-title {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 1.3em;
}
.profile {
  max-width: 130px;
  float: left;
  padding: 20px;
}
.container {
  display: flex;
  justify-content: space-between;
}
.container div {
  width: 50%;
  border-bottom: 2px inset;
  padding-bottom: 20px;
  padding-top: 20px;
}
.container div:first-child {
  border-right: 2px inset;
  padding-right: 20px;
}
.container div:last-child {
  padding-left: 20px;
}

.container img {
  max-width: 300px;
  width: 100%;
}

footer {
  font-size: 14px;
  padding-top: 30px;
  border-top: 1px solid #dad7d7;
  margin-top: 40px;
  text-align: center;
}

footer .link {
  margin-right: 15px;
}

.social-media-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

@media (min-width: 768px) {
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .social-media-icons {
    justify-content: flex-start;
  }
}

.social-media-icons .twitter-share-button {
  margin: 0 10px -5px;
}

.share-buttons {
  display: flex;
  align-items: center;
  background-color: #0176dc;
  text-decoration: none !important;
  color: #fff !important;
  border-radius: 3px;
  padding: 1px 6px;
  margin-bottom: -4px;
}

.share-buttons span {
  padding-left: 4px;
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 767px) {
  #main_container {
    padding: 40px 20px;
  }
  #resultDiv .button {
    margin: 15px 0;
    width: 100%;
    height: 40px;
  }
  .container {
    flex-direction: column;
  }
  .container div {
    width: 100%;
  }
  .container div:first-child {
    border-right: none;
    padding: 0;
  }
  .container div:last-child {
    padding-left: 0;
  }
  .grades_and_weights {
    width: 100%;
  }

  #section-four ol {
    padding-left: 14px;
  }
}
